// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agua-js": () => import("./../../../src/pages/agua.js" /* webpackChunkName: "component---src-pages-agua-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-index-js": () => import("./../../../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-servicos-caixas-de-visita-js": () => import("./../../../src/pages/servicos/caixas-de-visita.js" /* webpackChunkName: "component---src-pages-servicos-caixas-de-visita-js" */),
  "component---src-pages-servicos-diagnostico-e-consultoria-js": () => import("./../../../src/pages/servicos/diagnostico-e-consultoria.js" /* webpackChunkName: "component---src-pages-servicos-diagnostico-e-consultoria-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-sistemas-de-aducao-js": () => import("./../../../src/pages/servicos/sistemas-de-aducao.js" /* webpackChunkName: "component---src-pages-servicos-sistemas-de-aducao-js" */),
  "component---src-pages-servicos-sistemas-de-drenagem-js": () => import("./../../../src/pages/servicos/sistemas-de-drenagem.js" /* webpackChunkName: "component---src-pages-servicos-sistemas-de-drenagem-js" */)
}

